<template>
  <div class="corporate-culture">
    <div class="public-top">
      <div class="public-img">
        <p>风暴时刻 — 华美互通</p>
      </div>
    </div>
    <div class="corporate-culture-item">
      <!-- 只有label -->
      <el-row class="culture-item-1">
        <el-col :span="12"></el-col>
        <el-col :span="12">
          <span class="dot-span"></span>
          <span>遇见更好的华美</span>
        </el-col>
      </el-row>
      <el-row class="culture-item-2">
        <el-col :span="12">
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/1.png'"
                    fit="cover"
                    lazy></el-image>
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/2.png'"
                    fit="cover"
                    lazy></el-image>
        </el-col>
        <el-col class="title-img"
                :span="12">
          <span>这是一个暴风成长的团队，深耕于电商领域，成为中国第一批布局数据能力的品牌营销公司，京东数坊与京东内容机构首批官方认证服务商</span>
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/3.png'"
                    fit="cover"
                    lazy></el-image>
        </el-col>
      </el-row>
      <!-- 只有label -->
      <el-row class="cultrue-item-3">
        <el-col :span="12">
          <span class="dot-span"></span>
          <span>我的情绪我做主</span>
        </el-col>
        <el-col :span="12"></el-col>
      </el-row>
      <el-row class="cultrue-item-4">
        <el-col class="title-img"
                :span="12">
          <span>为了帮助员工更好的管理情绪，发挥自身的潜能，积极投入工作，华美互通特邀情绪管理讲师，为大家带来一场别开生面的情绪管理互动体验课程</span>
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/4.png'"
                    fit="cover"
                    lazy></el-image>
        </el-col>
        <el-col :span="12">
          <el-col :span="24">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/5.png'"
                          fit="cover"
                          lazy></el-image>
              </el-col>
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/6.png'"
                          fit="cover"
                          lazy></el-image>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/7.png'"
                      fit="cover"
                      lazy></el-image>
          </el-col>
        </el-col>
      </el-row>
      <!-- 只有label -->
      <el-row class="culture-item-1">
        <el-col :span="12"></el-col>
        <el-col :span="12">
          <span class="dot-span"></span>
          <span>新居乔迁一全新起航！</span>
        </el-col>
      </el-row>
      <el-row class="culture-item-2">
        <el-col :span="12">
          <el-col>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/8.png'"
                          fit="cover"
                          lazy />
              </el-col>
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/9.png'"
                          fit="cover"
                          lazy />
              </el-col>
            </el-row>
          </el-col>
          <el-col>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/10.png'"
                          fit="cover"
                          lazy />
              </el-col>
              <el-col :span="12">
                <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/11.png'"
                          fit="cover"
                          lazy />
              </el-col>
            </el-row>
          </el-col>
        </el-col>
        <el-col class="title-img"
                :span="12">
          <span>这是一个暴风成长的团队，深耕于电商领域，成为中国第一批布局数据能力的品牌营销公司，京东数坊与京东内容机构首批官方认证服务商</span>
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/corporate-culture/12.png'"
                    fit="cover"
                    lazy></el-image>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'corporate-culture',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.corporate-culture {
  .corporate-culture-item {
    width: 1080px;
    margin: 30px auto auto;
    .culture-item-1 {
      > div {
        &:nth-child(1) {
          padding: 20px 30px 32px;
        }
        &:nth-child(2) {
          position: relative;
          display: flex;
          align-items: flex-end;
          padding: 0;
          &::before {
            position: absolute;
            display: block;
            content: "";
            width: 500px;
            height: 3px;
            background: #ff730a;
          }
          > span:nth-child(2) {
            margin-left: 35px;
            font-size: 40px;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
    .culture-item-2 {
      > div:nth-child(1) {
        padding-bottom: 80px;
        > div.el-image:nth-child(1) {
          margin-bottom: 15px;
        }
        > div:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
    .cultrue-item-3 {
      > div {
        &:nth-child(1) {
          text-align: right;
        }
      }
      > div:nth-child(1) {
        position: relative;
        font-size: 40px;
        font-weight: 400;
        color: #000000;
        padding: 0 35px 0 0;
        > span:nth-child(1) {
          left: auto;
          right: -10px;
        }
        &::before {
          position: absolute;
          top: 50px;
          right: 0;
          content: "";
          width: 500px;
          height: 3px;
          background: #ff730a;
        }
      }
      > div:nth-child(2) {
        padding: 20px;
      }
    }
    .cultrue-item-4 {
      > div:nth-child(2) {
        > div:nth-child(1) {
          margin-bottom: 15px;
        }
      }
    }
    // 通用
    > div {
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        height: 90px;
      }
      > div {
        height: 100%;
        padding: 20px 30px 60px;
        &:nth-child(odd) {
          width: 50.2%;
          border-right: 4px solid #ff730a;
        }
        &:nth-child(even) {
          width: 49.8%;
        }
      }
    }
    .title-img {
      flex-direction: column;
      > span:nth-child(1) {
        display: inline-block;
        padding: 23px 0 70px 0;
        text-indent: 2em;
        font-size: 18px;
        font-weight: 300;
        color: #000000;
        line-height: 34px;
      }
    }
  }
}

.dot-span {
  position: relative;
  &:nth-child(1) {
    position: absolute;
    left: -10px;
    bottom: -7px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ff730a;
    &::before {
      position: relative;
      left: -3px;
      bottom: 3px;
      display: block;
      content: "";
      width: 18px;
      height: 18px;
      background: transparent;
      border-radius: 50%;
      border: 2px solid #ff730a;
    }
  }
}
</style>