<template>
  <div class="enterprise-trends-item">
    <div class="public-top">
      <div class="public-img">
        <p> 华美互通 —— 打造独一无二的成功案例 </p>
      </div>
    </div>
    <div class="trends-item">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/UserCase'}">用户案例</el-breadcrumb-item>
        <el-breadcrumb-item>{{page.label}}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card class="primary_coverage">
        <div class="primary_coverage-item">
          <div class="laebl">{{page.label}}</div>
          <el-divider></el-divider>
        </div>
        <div class="content"
             v-if="page.primary_coverage"
             v-html="page.primary_coverage"></div>
        <div class="pagecontent"
             v-else>
          <div class="dhk">内容可能被人偷走了</div>
          <el-image src="https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/2333.webp"
                    fit="cover"></el-image>
        </div>
        <div class="primary_coverage_bottom">
          <el-divider></el-divider>
          <el-image src="https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/hmhtlogo.png"
                    fit="cover"></el-image>
        </div>
      </el-card>

    </div>
  </div>
</template>

<script>
import { caseList } from '@/api/home'

export default {
  name: 'enterprise-trends-item',
  data () {
    return {
      id: this.$route.params.id,
      page: {}
    }
  },
  created () {
    let ldata = caseList()
    let qdata = ldata.find(item => item.id == this.id)
    this.page = qdata
  }
}
</script>

<style lang="scss" scoped>
.enterprise-trends-item {
  background: #f4f4f4;
  .trends-item {
    margin: 0 auto;
    padding-top: 20px;
    width: 1200px;
    .primary_coverage {
      margin-top: 20px;
      background: #ffffff;
      ::v-deep {
        .el-card__body {
          padding: 0 84px 30px 84px;
        }
      }
      .primary_coverage-item {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 30px;
        .laebl {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
        }
        .el-divider--horizontal {
          margin: 15px 0;
          height: 2px;
          background: #ff6d00;
        }
        .titletime {
          font-size: 14px;
          font-weight: 300;
          color: #737373;
        }
      }
      .content {
        ::v-deep {
        }
      }
      .primary_coverage_bottom {
        text-align: center;
        .el-image {
          width: 105px;
          height: 26px;
        }
      }
    }
  }
}

.pagecontent {
  position: relative;
  height: 400px;
  text-align: center;
  .el-image {
    position: absolute;
    width: 200px;
    left: 0;
    bottom: 0;
  }
  .dhk {
    position: absolute;
    padding: 40px 20px;
    bottom: 180px;
    left: 180px;
    border-radius: 10px;
    color: #ffffff;
    background: #737373;
    z-index: 999;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0px;
      width: 16px;
      height: 16px;
      border-width: 0;
      border-style: solid;
      border-color: transparent;
      border-right-width: 10px;
      border-right-color: currentColor;
      border-radius: 0 0 32px 0;
      color: #737373;
    }
  }
}
</style>