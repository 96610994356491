<template>
  <div class="home">
    <!-- 最上面的大图 -->
    <div class="home-Advertising_map home-img"
         :style="`background:url(${home_Advertising_map.Advertising_map}) no-repeat;`">
      <div>
        <div>
          <p>{{home_Advertising_map.Advertising_map_label}}</p>
          <p>{{home_Advertising_map.Advertising_map_title}}</p>
          <el-button class="el-but"
                     @click="toBottom">联系我们</el-button>
        </div>
      </div>
    </div>
    <!-- 时间线 -->
    <div class="home-Timeline">
      <div class="home-img"
           :style="`background:url(${home_Timeline.img}) no-repeat`">
        <div>
          <template v-for="(item,index) in home_Timeline.list">
            <div :key="index"
                 v-if="index<5">
              <p>{{item.titletime}}</p>
              <p>{{item.label}}</p>
              <p v-html="item.title"></p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 主营业务 -->
    <div class="home-Main-business">
      <div class="label-title">
        <div>主营业务</div>
      </div>
      <div class="Main-business">
        <template v-for="(item,index) in businessList">
          <!-- @mouseout.native.stop="businsshober('')" -->
          <el-radio :key="index"
                    @mouseover.native.stop="businsshober(index+1)"
                    v-model="Main_business"
                    :label="item.id"
                    border>
            <div>
              <el-image style="width: 65px; height: 80px;"
                        :src="item.icon"
                        fit="cover"></el-image>
              <span>{{item.label}}</span>
            </div>
          </el-radio>
        </template>
        <div class="Main-business-item">
          <!-- 数据业务 -->
          <template v-for="(item,index) in businessList">
            <div :class="`item${index+1}`"
                 :key="index"
                 v-show="Main_business == (index+1)">
              <template v-if="index==0 || index ==2">
                <el-image :style="index==0?'width:764px;height:369px':'width:611px;height:408px'"
                          :src="item.img" />
              </template>
              <div class="item-title">
                <div>
                  <p>{{item.toplabel}}</p>
                  <p>{{item.title}}</p>
                  <div>
                    <template v-for="(im,i) in item.titleList">
                      <p :key="i">{{im}}</p>
                    </template>
                  </div>
                </div>
              </div>
              <template v-if="index == 1 || index ==3">
                <el-image :style="index == 1 ? 'width:686px;height:382px':'width:710px;height:341px'"
                          :src="item.img" />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 案例展示 -->
    <div class="home-Case-display">
      <div class="label-title">
        <div>案例展示</div>
      </div>
      <div class="caseList">
        <div>
          <router-link to="/UserCase"
                       custom
                       v-slot="{ navigate }">
            <el-link @click="navigate"
                     @keypress.enter="navigate"
                     :underline="false">了解更多></el-link>
          </router-link>
        </div>
        <div>
          <template v-for="(item,index) in caseList">
            <XRouterLink :key="index"
                         :to="item.hrefs?item.hrefs:`/UserCase/CaseDetails/${item.id}`"
                         :isindex="item.hrefs?0:1"
                         v-if="index<6">
              <el-image style="width: 100%; height: 100%;"
                        :src="item.img"
                        fit="cover"
                        lazy></el-image>
              <span class="content">
                {{item.label}}
              </span>
            </XRouterLink>
          </template>
        </div>
      </div>
    </div>
    <!-- 企业荣誉-合作伙伴 -->
    <div class="home-Honor-partner">
      <!-- 企业荣誉 -->
      <div class="honor">
        <div class="label-title">
          <div>企业荣誉</div>
        </div>
        <div class="honor-item">
          <template v-for="(item,index) in honorList">
            <div :key="index">
              <el-image :src="item.img"
                        fit="cover"></el-image>
              <span class="honor-item-label">{{item.label}}</span>
              <span class="honor-item-title"
                    v-html="item.title"></span>
            </div>
          </template>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner">
        <div class="label-title">
          <div>合作伙伴</div>
        </div>
        <div class="partner-item">
          <template v-for="(item,index) in partnerList">
            <div class="partner-item-item"
                 :key="index">
              <el-image :style="`width:${item.w}px;height:${item.h}px`"
                        :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/partner/'+(index+1)+'.png'"
                        fit="cover"
                        lazy></el-image>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 企业动向 -->
    <div class="home-Enterprise-trends">
      <div class="label-title">
        <div>企业动向</div>
      </div>
      <el-row>
        <el-col :span="12">
          <template v-for="(activity, index) in enterprise_trends.activities">
            <div :key="index"
                 v-if="index<=2">
              <el-image :key="index"
                        v-show="enterprise_trends.id == activity.id"
                        :src="activity.img"
                        fit="cover"></el-image>
            </div>
          </template>
        </el-col>
        <el-col :span="12">
          <el-timeline>
            <template v-for="(activity, index) in enterprise_trends.activities">
              <el-timeline-item :key="index"
                                v-if="index<=2"
                                @mouseenter.native="enterprise_trends.id = activity.id">
                <router-link :to="`/enterprise_trends/trend-details/${activity.id}`"
                             custom
                             v-slot="{ navigate }">
                  <el-link @click="navigate"
                           @keypress.enter="navigate"
                           :underline="false">
                    <div>{{activity.titletime}}</div>
                    <div>{{activity.label}}</div>
                    <span>了解更多></span>
                  </el-link>
                </router-link>
              </el-timeline-item>
            </template>
          </el-timeline>
        </el-col>
      </el-row>
      <div>
        <router-link to="/enterprise_trends"
                     custom
                     v-slot="{ navigate }">
          <el-link @click="navigate"
                   @keypress.enter="navigate"
                   :underline="false">
            了解更多>
          </el-link>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { timeline, businessList, caseList, data, HonorList } from '@/api/home'
import XRouterLink from '@/components/XRouterLink'

export default {
  name: 'HomeView',
  components: { XRouterLink },
  data () {
    return {
      show: [],
      // 最上面的图及其中的文字
      home_Advertising_map: {
        Advertising_map: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/banner.png',
        Advertising_map_label: '数投运商业生态服务',
        Advertising_map_title: '华美已经发展成「数据策略型品牌全案」机构，并以 数据策略、品牌全案、创意营销、电商运营四大业务 板块贯穿了品牌的全生态服务。',
      },
      //   时间线及其中的文字
      home_Timeline: {
        img: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/Timeline.png',
        list: timeline()
      },
      //   主营业务已选中
      Main_business: 1,
      hover_business: '',
      //   主营业务 数据，方便修改或者使用动态数据
      businessList: businessList(),
      // 案例展示
      caseList: caseList(),
      // 企业荣誉
      honorList: HonorList(),
      // 合作伙伴图表宽高
      partnerList: [
        { w: 60, h: 61 }, { w: 73, h: 73 }, { w: 158, h: 33 }, { w: 99, h: 47 }, { w: 100, h: 34 },
        { w: 75, h: 61 }, { w: 145, h: 49 }, { w: 112, h: 52 }, { w: 161, h: 79 }, { w: 85, h: 47 },
        { w: 50, h: 50 }, { w: 73, h: 59 }, { w: 115, h: 42 }, { w: 99, h: 74 }, { w: 87, h: 68 },
        { w: 102, h: 58 }, { w: 87, h: 58 }, { w: 110, h: 38 }, { w: 125, h: 39 }, { w: 130, h: 54 },
      ],
      // 企业动向
      enterprise_trends: {
        id: data().reverse()[0].id,
        // 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/left_png.png'),
        activities: data().reverse()
      }
    }
  },
  mounted () {
    // // 监听滚动条
    // window.addEventListener('scroll', (item) => {
    //   var offsetTop = item.offsetTop;
    //   console.log(offsetTop)
    // })
  },
  methods: {
    toBottom () {
      window.scrollTo(0, 50000);
    },
    businsshober (index) {
      this.Main_business = index
    }
  }
}
</script>

<style lang="scss" scoped>
.home-img {
  background-size: 100% 100% !important;
}
.home {
  // 最上方的大图及内容
  .home-Advertising_map {
    height: 610px;
    > div {
      margin: 0 auto;
      padding-top: 140px;
      width: 1200px;
      > div {
        width: 433px;
        > p:nth-child(1) {
          color: #0e0e0e;
          font-size: 48px;
        }
        > p:nth-child(2) {
          color: #666666;
          margin: 31px auto;
          line-height: 38px;
          font-size: 18px;
        }
        .el-but {
          width: 143px;
          height: 51px;
          color: #ff6d00;
          border: 1px solid #ff6d00;
          &:hover,
          &:focus,
          &:active {
            color: #ffffff;
            background: #ff6d00;
          }
        }
      }
    }
  }
  // 时间线及内容
  .home-Timeline {
    margin-top: 40px;
    > div {
      margin: 0 auto;
      padding: 0 7px;
      max-width: 1200px;
      height: 256px;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        > div {
          position: relative;
          top: 110px;
          //   text-align: center;
          float: left;
          display: flex;
          align-items: center;
          //   justify-content: center;
          flex-direction: column;
          width: 20%;
          height: 100%;
          > p:nth-child(1) {
            font-size: 22px;
            font-weight: bold;
          }
          > p:nth-child(2) {
            font-size: 22px;
            color: #ff6e01;
            font-weight: bold;
          }
          > p:nth-child(3) {
            text-align: center;
            // width: 140px;
            font-size: 14px;
            color: #666666;
          }
        }
        > div:nth-child(even) {
          top: -117px;
          flex-direction: column-reverse;
        }
      }
    }
  }
  // 主营业务
  .home-Main-business {
    margin-top: 55px;
    .Main-business {
      margin: 36px auto;
      max-width: 1200px;
      ::v-deep {
        .el-radio {
          color: #0e0e0e;
          &:hover {
            background: #fff8f2;
          }
          margin-right: 0;
          .el-radio__label {
            color: #0e0e0e;
            > div {
              display: flex;
              align-items: center;
              flex-direction: column;
              > .el-image {
                margin-bottom: 10px;
              }
            }
          }
          &.is-bordered {
            width: 260px;
            height: 146px;
            padding: 0;
            border: 1px dashed #ff6d00;
            border-radius: 8px;
            .el-radio__input {
              display: none;
            }
            + .el-radio.is-bordered {
              margin-left: 53px !important;
            }
            &.is-checked {
              color: #0e0e0e !important;
              background: #fff8f2;
            }
          }
        }
      }
      .Main-business-item {
        margin-top: 39px;
        height: 370px;
        // 公共部分
        .item-title {
          float: left;
          > div {
            > p:nth-child(1) {
              margin-top: 10px;
              margin-bottom: 25px;
              font-size: 32px;
              color: #0e0e0e;
            }
            > p:nth-child(2) {
              margin-bottom: 40px;
              font-size: 16px;
              line-height: 28px;
              color: #666666;
            }
            > div:nth-child(3) {
              p {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 20px;
                font-size: 20px;
                color: #666666;
                &::before {
                  position: absolute;
                  display: block;
                  left: 0;
                  content: "";
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  color: #ff6d00;
                  background: #ff6d00;
                }
                & + p {
                  margin-top: 32px;
                }
              }
            }
          }
        }
        > div {
          position: absolute;
          background: #ffffff !important;
          ::v-deep {
            .el-image {
              background: #ffffff !important;
              > img {
                background: #ffffff !important;
              }
            }
          }
          > div {
            float: left;
          }
        }
        // 数据业务
        .item1 {
          > div:nth-child(1) {
            margin-right: 50px;
          }
          > div:nth-child(2) {
            width: 385px;
          }
        }
        // 投放业务
        .item2 {
          > div:nth-child(1) {
            width: 451px;
            margin-right: 60px;
          }
        }
        // 运营业务
        .item3 {
          > div:nth-child(1) {
            margin-left: 88px;
            margin-right: 83px;
          }
          > div:nth-child(2) {
            width: 385px;
          }
        }
        // 创意业务
        .item4 {
          > div:nth-child(1) {
            width: 369px;
            margin-right: 100px;
          }
        }
      }
    }
  }
  // 案例展示
  .home-Case-display {
    margin: 0 auto;
    padding-bottom: 50px;
    max-width: 1200px;
    height: 870px;
    .caseList {
      // 了解更多按钮
      > div:nth-child(1) {
        text-align: right;
        a {
          color: #ff6e01;
        }
      }
      //   案列展示list列表
      > div:nth-child(2) {
        height: 761px;
        margin-top: 20px;
        a {
          position: relative;
          float: left;
          width: 360px;
          height: 360px;
          background: #dedede;
          border-radius: 10px;
          cursor: pointer;
          &:hover {
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 70%;
              padding: 0 54px;
            }
          }

          &:nth-child(-n + 3) {
            margin-bottom: 40px;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6) {
            margin-left: 60px;
          }
          span {
            position: absolute;
            display: block;
            width: 97.5%;
            height: 40px;
            bottom: 0;
            line-height: 40px;
            color: #ffffff;
            background: #00000078;
            padding-left: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
  // 企业荣誉 - 合作伙伴
  .home-Honor-partner {
    background: #f1f3f5;
    // 公共样式
    > div {
      margin: 0 auto;
      padding-top: 45px;
      max-width: 1200px;
    }
    // 企业荣誉
    .honor-item {
      margin-top: 35px;
      height: 450px;
      > div {
        float: left;
        width: 284.5px;
        height: 446px;
        background: #ffffff;
        border: 1px dashed #ff6d00;
        border-radius: 10px;
        & + div {
          margin-left: 18px;
        }
        .el-image {
          width: 268px;
          height: 210px;
          margin: 8px;
        }
        .honor-item-label {
          margin: 0 auto;
          display: block;
          text-align: center;
          color: #ffffff;
          font-size: 20px;
          width: 245px;
          height: 28px;
          background: #ff6e01;
          box-shadow: 7px 7px 0px 0px rgba(255, 110, 1, 0.13);
        }
        .honor-item-title {
          margin: 25px auto;
          display: block;
          width: 243px;
          font-weight: 300;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
    // 合作伙伴
    .partner-item {
      margin-top: 35px;
      height: 580px;
      > div {
        float: left;
        width: 226px;
        height: 114px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        margin-top: 25px;
        margin-right: 15px;
        // cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(-n + 5) {
          margin-top: 0;
        }
        &:nth-child(5n + 5) {
          margin-right: 0;
        }
        .el-image {
          height: 100%;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   height: auto;
          //   min-height: 100%;
          //   ::v-deep {
          //     .el-image__inner {
          //       //   width: auto;
          //       //   min-height: 100%;

          //       //   height: 100%;
          //       // max-width: 40%;
          //     }
          //   }
        }
      }
    }
  }
  // 企业动向
  .home-Enterprise-trends {
    padding-top: 30px;
    margin: 0 auto;
    max-width: 1200px;
    > div:nth-child(1) {
      margin-bottom: 60px;
    }
    > div:nth-child(2) {
      .el-image {
        width: 518px;
        height: 350px;
        margin: 0 40px;
      }
      .el-timeline {
        ::v-deep {
          .el-timeline-item {
            padding-bottom: 0;
            &:nth-child(n):not(:last-child) {
              padding-bottom: 35px;
            }
            &:hover {
              .el-timeline-item__node {
                position: absolute;
                top: -5px;
                left: -8px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #ffffff;
                border: 7px solid #ff6e00;
                -moz-border-top-colors: #ff6e00 #ffae01;
                -moz-border-right-colors: #ff6e00 #ffae01;
                -moz-border-bottom-colors: #ff6e00 #ffae01;
                -moz-border-left-colors: #ff6e00 #ffae01;
                box-shadow: 1px 6px 4px 0px rgba(255, 84, 50, 0.31);
              }
              .el-timeline-item__wrapper
                .el-timeline-item__content
                .el-link
                .el-link--inner
                > div:nth-child(2) {
                color: #ff6d00;
              }
            }
            .el-timeline-item__content {
              .el-link {
                font-size: 12px;
                color: #999999;
                .el-link--inner {
                  > div:nth-child(1) {
                    font-size: 16px;
                    color: #0e0e0e;
                  }
                  > div:nth-child(2) {
                    margin-top: 8px;
                    margin-bottom: 10px;
                    font-size: 26px;
                    color: #0e0e0e;
                  }
                }
              }
            }
          }
        }
      }
    }
    > div:nth-child(3) {
      text-align: center;
      color: #999999;
      font-size: 16px;
      .el-link {
        &:hover {
          color: #ff6d00;
        }
      }
    }
  }
}
</style>