<template>
  <div class="enterprise-trends-item">
    <div class="public-top">
      <div class="public-img">
        <p> 心所向 至未来 — 华美互通 </p>
      </div>
    </div>
    <div class="trends-item">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/enterprise_trends'}">企业动向</el-breadcrumb-item>
        <el-breadcrumb-item>{{page.label}}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card class="primary_coverage">
        <div class="primary_coverage-item">
          <div class="laebl">{{page.label}}</div>
          <el-divider></el-divider>
          <div class="titletime">{{page.titletime}}</div>
        </div>
        <div class="content"
             v-html="page.primary_coverage"></div>
        <div class="primary_coverage_bottom">
          <el-divider></el-divider>
          <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/hmhtlogo.png'"
                    fit="cover"></el-image>
        </div>
      </el-card>

    </div>
  </div>
</template>

<script>
import { data } from '@/api/home'

export default {
  name: 'enterprise-trends-item',
  data () {
    return {
      id: this.$route.params.id,
      page: {}
    }
  },
  created () {
    let ldata = data()
    let qdata = ldata.find(item => item.id == this.id)
    this.page = qdata
  }
}
</script>

<style lang="scss" scoped>
.enterprise-trends-item {
  background: #f4f4f4;
  .trends-item {
    margin: 0 auto;
    padding-top: 20px;
    width: 1200px;
    .primary_coverage {
      margin-top: 20px;
      background: #ffffff;
      ::v-deep {
        .el-card__body {
          padding: 0 84px 30px 84px;
        }
      }
      .primary_coverage-item {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 30px;
        .laebl {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
        }
        .el-divider--horizontal {
          margin: 15px 0;
          height: 2px;
          background: #ff6d00;
        }
        .titletime {
          font-size: 14px;
          font-weight: 300;
          color: #737373;
        }
      }
      .content {
        ::v-deep {
          section {
            text-align: center;
          }
          img {
            // width: 100% !important;
          }

          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #e9e7e7;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #ff6d00; /*rgba(0,0,0,1);*/
            border-radius: 10px;
          }
          ::-webkit-scrollbar-track {
            background-color: transparent;
          }
        }
      }
      .primary_coverage_bottom {
        text-align: center;
        .el-image {
          width: 105px;
          height: 26px;
        }
      }
    }
  }
}
</style>