<template>
  <div class="enterprise-trends">
    <div class="public-top">
      <div class="public-img">
        <p> 心所向 至未来 — 华美互通 </p>
      </div>
    </div>
    <div class="enterprise-trends-item">
      <label>企业动向</label>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>企业动向</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
      <div class="trends-item-list">
        <template v-for="(item,index) in TrendsItemList">
          <div :key="index">
            <router-link :to="`/enterprise_trends/trend-details/${item.id}`">
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-image :src="item.img"
                            fit="cover"
                            lazy></el-image>
                </el-col>
                <el-col :span="16">
                  <div class="titletime">{{item.titletime}}</div>
                  <div class="label">{{item.label}}</div>
                  <div class="title">{{item.title}}</div>
                </el-col>
              </el-row>
            </router-link>
            <el-divider></el-divider>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from '@/api/home'

export default {
  name: 'enterprise-trends',
  data () {
    return {
      TrendsItemList: data()
    }
  }
}
</script>

<style lang="scss" scoped>
.enterprise-trends {
  .enterprise-trends-item {
    margin: 0 auto;
    padding-top: 10px;
    width: 1200px;
    text-align: center;
    label {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
    }
    .trends-item-list {
      margin-top: 20px;
      .el-divider--horizontal {
        margin: 20px 0;
      }
      a {
        display: block;
        height: 251px;
        .el-row {
          height: 100%;
          > div {
            &:nth-child(1),
            &:nth-child(2) {
              height: 100%;
            }
            ::v-deep {
              .el-image {
                height: 100%;
              }
            }
          }
          > div:nth-child(2) {
            text-align: left;
            .titletime {
              font-size: 14px;
              font-weight: 300;
              color: #737373;
            }
            .label {
              margin-top: 20px;
              font-size: 26px;
              font-weight: 400;
              color: #333333;
            }
            .title {
              margin-top: 20px;
              font-size: 16px;
              font-weight: 200;
              color: #333333;
              line-height: 33px;
            }
          }
        }
      }
    }
    .el-divider--horizontal {
      margin: 8px 0;
    }
  }
}
</style>