<template>
  <div class="Join-Us">
    <div class="public-top">
      <div class="public-img">
        <p>点燃激情 扬帆起航 — 华美互通 </p>
      </div>
    </div>
    <div class="Join-Us-item">
      <div class="Join-Us-label">关于我们</div>
      <div class="Join-Us-title">
        {{page.title}}
      </div>
      <div class="Join-Us-img">
        <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/join_us.png'"
                  fit="cover"></el-image>
      </div>
      <div class="Join-Us-dq">
        <span>电商文案策划</span>
        <span>广告投放实习生</span>
        <span>数据挖掘工程师</span>
        <span>资深数据分析师</span>
        <span>媒介助理</span>
        <span>数据运营总监</span>
        <span>AE执行</span>
      </div>
      <div class="Recruitment-position">
        <p>招聘职位</p>
        <span class="title-icon">>></span>
        <div>
          <el-link :underline="false"
                   href="https://www.zhipin.com/job_detail/?query=%E5%8C%97%E4%BA%AC%E5%8D%8E%E7%BE%8E%E4%BA%92%E9%80%9A%E5%B9%BF%E5%91%8A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&city=100010000&industry=&position="
                   target="_blank">
            <span class="title-bg">BOSS直聘</span>
          </el-link>
          <el-link :underline="false"
                   href="https://www.liepin.com/company/12320445/"
                   target="_blank">
            <span class="title-bg">猎聘网</span>
          </el-link>
        </div>
      </div>
      <el-card class="Join-Us-Contact">
        <div>电话：{{page.phone}}</div>
        <div>邮箱：{{page.mailbox}}</div>
        <div>地址：{{page.address}}</div>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Join-Us',
  data () {
    return {
      page: {
        title: '自2012年华美便以电商营销作为服务核心，历经中国商业发展的10年变迁，华美已经从传统广告营销全面发展成为「 数据策略型品牌全案」机构，并以数据策略、品牌全案、创意营销、电商运营四大业务板块贯穿了品牌的全生态服务。',
        phone: '15600138075',
        mailbox: 'lixin@huameihutong.com',
        address: '北京市大兴亦庄经济技术开发区荣华南路1号国锐·金嵿广场A座7层'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Join-Us {
  .Join-Us-item {
    padding-top: 40px;
    margin: 0 auto;
    width: 1200px;
    .Join-Us-label {
      text-align: center;
      color: #000000;
      font-weight: 400;
      font-size: 52px;
    }
    .Join-Us-title {
      margin: 0 auto;
      width: 930px;
      margin-top: 35px;
      margin-bottom: 85px;
      text-indent: 2em;
      font-size: 22px;
      font-weight: 400;
      color: #000000;
      line-height: 47px;
    }
    .Join-Us-img {
      margin: 0 auto;
      width: 832px;
      height: 111px;
    }
    .Join-Us-dq {
      position: relative;
      margin-top: 45px;
      height: 597px;
      background: url("https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/join_us_dq.png")
        no-repeat center top;
      background-size: auto 100% !important;
      span {
        position: absolute;
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        line-height: 10px;
        &:nth-child(1) {
          top: 0;
          right: 330px;
        }
        &:nth-child(2) {
          top: 80px;
          left: 145px;
        }
        &:nth-child(3) {
          top: 110px;
          right: 175px;
        }
        &:nth-child(4) {
          top: 305px;
          right: 80px;
        }
        &:nth-child(5) {
          bottom: 205px;
          left: 155px;
        }
        &:nth-child(6) {
          bottom: 125px;
          right: 200px;
        }
        &:nth-child(7) {
          bottom: 30px;
          left: 300px;
        }
      }
    }
    .Join-Us-Contact {
      margin: 0 auto;
      margin-top: 73px;
      width: 1045px;
      height: 310px;
      ::v-deep {
        .el-card__body {
          padding: 52px;
          font-size: 24px;
          > div {
            width: 100%;
            line-height: 52px;
          }
        }
      }
    }
    .Recruitment-position {
      text-align: center;
      margin-top: 25px;
      p {
        position: relative;
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
      }
      .title-icon {
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        font-size: 27px;
        font-weight: bold;
        color: #f8862d;
        line-height: 47px;
        transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        /* Internet Explorer 9*/
        -moz-transform: rotate(270deg);
        /* Firefox */
        -webkit-transform: rotate(270deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(270deg);
        /* Opera */
      }
      div {
        > a {
          & + a {
            margin-left: 20px;
          }
          .title-bg {
            margin: 0 auto;
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            width: 183px;
            height: 45px;
            font-size: 27px;
            font-weight: 400;
            border-radius: 25px;
            color: #ffffff;
            background: url("https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/xbg.png")
              no-repeat center top;
            background-size: 100% 100% !important;
          }
        }
      }
    }
  }
}
</style>