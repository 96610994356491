<template>
  <div class="user-case">
    <div class="public-top">
      <div class="public-img">
        <p>华美实战案例</p>
        <p>我们为多个品牌打造过独一无二的成功案例</p>
      </div>
    </div>
    <div class="user-case-item">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户案例</el-breadcrumb-item>
      </el-breadcrumb>
      <waterfall class="caseList"
                 :col="3"
                 :width="380"
                 :data="caseList"
                 :gutterWidth="30">
        <template v-for="(item,index) in caseList">
          <el-card :key="index">
            <XRouterLink :to="item.hrefs?item.hrefs:`/UserCase/CaseDetails/${item.id}`"
                         :isindex="item.hrefs?0:1">
              <el-image :src="item.titleimg"
                        fit="cover"></el-image>
              <div class="content">
                <div>{{item.label}}</div>
                <div v-html="item.title"></div>
                <div>查看详情></div>
              </div>
            </XRouterLink>
          </el-card>
        </template>
      </waterfall>
    </div>
  </div>
</template>

<script>
import { caseList } from '@/api/home'
import XRouterLink from '@/components/XRouterLink'

export default {
  name: 'user-case',
  components: { XRouterLink },
  data () {
    return {
      currency_top: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/currency-top.png',
      caseList: caseList()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-case {
  background: #f1f3f5;
  .user-case-item {
    margin: 0 auto;
    width: 1200px;
    margin-top: 20px;
    margin-bottom: 15px;
    .caseList {
      margin-top: 20px;
      .vue-waterfall-column {
        > .el-card {
          border-radius: 10px;
          ::v-deep {
            .el-card__body {
              padding: 0;
              a {
                text-decoration: none;
                .el-image {
                  .el-image__inner {
                    opacity: 1;
                  }
                }
                .content {
                  padding: 20px;
                  > div:nth-child(1) {
                    margin-bottom: 20px;
                    font-size: 22px;
                    font-weight: 400;
                    color: #5c5b5b;
                  }
                  > div:nth-child(2) {
                    margin-bottom: 30px;
                    font-size: 14px;
                    font-weight: 200;
                    color: #666666;
                    line-height: 29px;
                  }
                  > div:nth-child(3) {
                    margin-bottom: 50px;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 200;
                    color: #666666;
                  }
                }
              }
            }
          }
          & + .el-card {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>